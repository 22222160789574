import React, {useState, useEffect} from 'react'
import { AnimatePresence } from 'framer-motion';
import CssBaseline from "@material-ui/core/CssBaseline";
import { ConnectedRouter } from 'connected-react-router'

import TransactionWidget from "../widgets/TransactionWidget";
import Landing from "../../pages/landing"

import {connect} from 'react-redux'
import { Route, Router, Switch, Redirect } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import getTheme from '../../themes'
import ReactGA from 'react-ga';

import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import Web3Connecter from "../widgets/web3connector"
import { createAlchemyWeb3 } from "@alch/alchemy-web3";

let rlsContractAddress;
let rlsContractABI;

let cellarContractAddress;
let cellarContractABI;

if (process.env.REACT_APP_CLUSTER === "production") {
  import(
  	'../../contracts/RLS.json'
  	).then(({default: json}) => {
  		rlsContractABI = (json.abi)
  		rlsContractAddress = (json.networks[1].address)
  });

  import(
  	'../../contracts/Cellar.json'
  	).then(({default: json}) => {
  		cellarContractABI = (json.abi)
  		cellarContractAddress = (json.networks[1].address)
  });
} else {
 import(
 	'../../contracts/RLS_RINKEBY.json'
 	).then(({default: json}) => {
 		rlsContractABI = (json.abi)
 		rlsContractAddress = (json.networks[4].address)
 });

 import(
 	'../../contracts/Cellar.json'
 	).then(({default: json}) => {
 		cellarContractABI = (json.abi)
 		cellarContractAddress = (json.networks[4].address)
 });
}

const providerOptions = {
	walletconnect: {
		package: WalletConnectProvider, // required
		options: {
			infuraId: "65f4039651654a9c9ce764f6482a2016" // required
		},
		rpc: {
			1: `https://eth-mainnet.alchemyapi.io/v2/yme7E4MsJ5LeMPGc05qPfV3bdFVaNi6P`,
			4: `https://eth-rinkeby.alchemyapi.io/v2/KK-2VD26jwloEmJ7TFdmz9mon7bLa6z9`
		}
	}
};

function initWeb3(provider: any) {
  const web3 = createAlchemyWeb3(process.env.REACT_APP_CLUSTER === "production"
		?
			`https://eth-mainnet.alchemyapi.io/v2/yme7E4MsJ5LeMPGc05qPfV3bdFVaNi6P`
		:
			`https://eth-rinkeby.alchemyapi.io/v2/KK-2VD26jwloEmJ7TFdmz9mon7bLa6z9`);

  web3.setWriteProvider(provider)

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });

  return web3;
}

const INITIAL_STATE = {
  fetching: false,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: process.env.REACT_APP_CLUSTER === "production" ? 1 : 4,
  networkId: process.env.REACT_APP_CLUSTER === "production" ? 1 : 4,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null
};

const App = ({ history }) => {
	const setupTheme = () => {
		localStorage.setItem("pool_theme", "DARK")
		return "DARK"
	}

  const [appTheme, setAppTheme] = useState(getTheme(localStorage.getItem("pool_theme") || setupTheme()))

  const _web3Modal = new Web3Modal({
		cacheProvider: true, // optional
		providerOptions // required
	});

  const [pendingList, setPendingList] = useState([])
	const [messageList, setMessageList] = useState([])
  const [web3Modal, setWeb3Modal] = useState(_web3Modal)
  const [state, setState] = useState({
    web3: null,
    proxy: null,
    provider: null,
    connected: false,
    chainId: 1,
    networkId: 1,
    price: 0,
    maxSupply: 0,
    showModal: false,
    pendingRequest: false,
    result: null,
    fetching: false,
    address: "",
    balances: {}

  });

  const modalConnect = async () => {
    return await web3Modal.connect();
  }

	useEffect(() => {
    if (state.connected) {
      let userCount;
      let totalSupply;
      let bal;
      getUserCount().then((count) => {
        if (state.balances.userCount !== count) {
          userCount = count
          return state.web3.eth.getBalance(state.address)
        }
      }).then((eth) => {
        if (eth) {
          bal = eth
          return state.proxy.nftContract.methods.totalSupply().call()
        }
      }).then((supply) => {
        if (supply) {
          totalSupply = supply
          return state.proxy.gfcContract.methods.balanceOf(state.address).call()
        }
      }).then((gfc) => {
        if (gfc) {
          updateState({
            eth: parseFloat(state.web3.utils.fromWei(`${bal}`)).toFixed(4),
            userCount: userCount,
            totalSupply: totalSupply,
            gfcBal: parseFloat(state.web3.utils.fromWei(`${gfc}`)).toFixed(4),
          })
        }
      })
    }
	},[pendingList, messageList])

  const getUserCount = async () => {
    return await state.proxy.nftContract.methods.balanceOf(state.address).call()

  }

	useEffect(() => {
    listenForMint()

	},[state])

  useEffect(() => {
    ReactGA.initialize('UA-204299441-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  useEffect(() => {
		if (!history.location.pathname.includes("/view")) {
	    // modalConnect().then((provider) => {
	    //   console.log("CONNECTING")
	    //   return new Web3(provider)
	    // }).then((web3) => {
	    //   console.log("WEB3: ", web3)
      //
	    //   if(web3Modal.cachedProvider) {
	    //     console.log("cachedProvider: ", web3Modal.cachedProvider)
	    //   }
	    // });
		}
  },[web3Modal])

  const onConnect = async () => {
    const provider = await web3Modal.connect();
    await subscribeProvider(provider);
    const web3: any = initWeb3(provider);
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    const chainId = await web3.eth.chainId();
    const nftContract = new web3.eth.Contract(rlsContractABI, rlsContractAddress);
    const cellarContract = new web3.eth.Contract(cellarContractABI, cellarContractAddress);

    let supply = await nftContract.methods.totalSupply().call()
    let max_supply = await nftContract.methods.MAX_NFT_SUPPLY().call()

    let price = process.env.REACT_APP_CLUSTER === "production" ? .8 : 0;

    let userCount = await nftContract.methods.balanceOf(address).call()
    let bal = await web3.eth.getBalance(address);

    history["web3Object"] = {
      web3: web3,
      connected: true,
      currentAccount: address,
      appTheme: appTheme,

      nftContract: nftContract,
      cellarContract: cellarContract,
      cellarAddress: cellarContractAddress,
      pendingList: pendingList,
      closePending: (e) => {closePending(e)},
      closeTx: (e) => {closeTx(e)},
      setPendingList: (e) => {setPendingList(pendingList => [...pendingList, e])},
      messageList: messageList,
    }

    setState({
      web3: web3,
      provider: provider,
      connected: true,
      chainId: chainId,
      networkId: networkId,
      showModal: false,
      maxSupply: max_supply,
      price: price,
      pendingRequest: false,
      result: null,
      fetching: false,
      address: address,
      proxy: history.web3Object,
      balances: {
        eth: parseFloat(web3.utils.fromWei(`${bal}`)).toFixed(4),
        userCount: userCount,
        totalSupply: supply
      }
    });

    // listenForClaim()
  }

  const subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => resetApp());
    provider.on("accountsChanged", async (accounts: string[]) => {
      await setState({ address: accounts[0] });
    });
    provider.on("chainChanged", async (chainId: number) => {
      const { web3 } = this.state;
      const networkId = await web3.eth.net.getId();
      await setState({ chainId, networkId });
    });

    provider.on("networkChanged", async (networkId: number) => {
      const { web3 } = state;
      const chainId = await web3.eth.chainId();
      await setState({ chainId, networkId });
    });
  };

  const resetApp = async () => {
    const { web3 } = state;

    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await web3Modal.clearCachedProvider();

    setState({ ...INITIAL_STATE });
    history.go(0)
  };

  const updateTheme = (t) => {
    localStorage.setItem("pool_theme", t)
    setAppTheme(getTheme(t))
  }

  const listenForMint = () => {
      if (state.connected) {
        state.proxy.cellarContract.events.Minted().on('data', (res) => {
          setMessageList(prevMessage => ({...prevMessage, [res.transactionHash]: res}))
        })
      }


	}

	const closeTx = (id) => {
		let x = messageList;
		delete x[id]
		setMessageList({...x})
	}

	const closePending = (id) => {
		let x = pendingList;
		var filtered = x.filter(function(value, index, arr){
				return value !== id;
		});
		setPendingList(filtered)
	}

  const updateState = (additions) => {
    let newState = {
      ...state,
      balances: {
        ...additions
      }
    }
    setState(newState)
  }

  return (
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={appTheme} >
        <CssBaseline />
        <AnimatePresence exitBeforeEnter initial={false} >
          <Switch>
            <Route exact path="/">
              <Landing
                state={state}
                connectWallet={() => {onConnect()}}
                disconnectWallet={() => {resetApp()}}
              />
            </Route>
            <Redirect to="/"/>
          </Switch>
					{
						history["web3Object"]
							?
								<TransactionWidget closeTx={(e) => {closeTx(e)}} closePending={(e) => {closePending(e)}} web3={history["web3Object"].web3} pendingList={pendingList} messageList={messageList} currentAccount={history["web3Object"].currentAccount} contract={state.contract} />
							:<></>
					}
        </AnimatePresence>
      </MuiThemeProvider>
    </ConnectedRouter>

  );
}
export default (App);
