import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
  transactionWidgetWrapper: {
    position: "fixed",
    zIndex: 10000000000,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-evenly",
    height: "fit-content",
    bottom: 5,
    right: 20,
    backgroundColor: 'transparent'
  },
  completed:{
    backgroundColor: theme.body.backgroundAccent,
    border: `2px solid ${theme.body.basePageColor}`,
    color: theme.text.primary
  },
  pending:{
    backgroundColor: theme.body.backgroundAccent,
    border: `2px solid ${theme.body.basePageColor}`,

    color: theme.text.primary

  },
  messageHeader: {
    fontWeight: 100,
    fontSize: 20,
    color: "#d55eff"
  },
  messageLink: {
    fontSize: 13,

    "& a": {
      color: theme.text.primary,

      "&:hover": {
        color: "#00bbfe"
      }
    }
  },

  tokenID: {
    fontWeight: 500,
    fontSize: 25,
    position: "absolute",
    right: 10,
    top: 10,
    color: "#b50cff"
  },
  closeMe: {
    position: "absolute",
    color: "#4aa2f2",
    width: 50,
    fontSize: 15,
    padding: 0,
    height: 50,
    right: 0,
    top: 0,
    zIndex: 100000,

    "&:hover": {
      color: "red"
    }
  }
}))

export default function TransactionWidget(props) {
	const dispatch = useDispatch()
  const classes = useStyles()

	const { Settings, Compiled } = useSelector(state => state)

  const [pendingList, setPendingList] = useState([]);
  const [messageList, setMessageList] = useState([]);

  let prodetherscan = "https://etherscan.io/tx/"
  let rinketherscan = "https://rinkeby.etherscan.io/tx/"

	useEffect(() => {
    if (props.pendingList !== pendingList) {
      setPendingList(props.pendingList)
    }
    if (props.messageList !== messageList) {
      setMessageList(props.messageList)
    }
	},[props.pendingList, props.messageList])

  const getEventMessage = (message) => {
    switch(message["event"]) {
      case("Minted"):
        if (message.returnValues._owner === props.currentAccount) {
          return `Transaction Finalized !`
        }
        break;
    }
  }
  const renderMessageItems = () => {
    let messages = []
    // console.log(messageList)
    for (const [id, message] of Object.entries(messageList)) {
      messages.push(
        <>
          <div className={`${classes.completed} flex p-4 px-6 flex-col relative w-1/2 md:w-full justify-between   right-0 mb-4`}>
            <button onClick={(e) => {props.closeTx(id)}}className={classes.closeMe}>X</button>
            <div className={`flex-grow outline-none flex font-thin items-center justify-left text-xl tracking-tight leading-10`}>{getEventMessage(message)}</div>
            <div className={`${classes.messageLink} overflow-hidden`}>
              <a target="blank" href={`${process.env.REACT_APP_CLUSTER === "production" ? prodetherscan : rinketherscan}${id}`}>{id}</a>
            </div>
          </div>
        </>
      )
    }

    for (let _pending of pendingList) {
      messages.push(
        <>
          <div className={`${classes.pending}  flex p-4 px-6 flex-col relative w-1/2 md:w-full justify-between right-0  mb-4 overflow-hidden`}>
            <button onClick={(e) => {props.closePending(_pending)}}className={classes.closeMe}>X</button>
            <div className={'flex-grow outline-none font-thin flex items-center justify-left text-xl tracking-tight leading-10'}>Transaction Pending . . .</div>
            <div className={`${classes.messageLink} overflow-hidden`}>
              <a target="blank" href={`${process.env.REACT_APP_CLUSTER === "production" ? prodetherscan : rinketherscan}${_pending}`}>{_pending}</a>
            </div>
          </div>
        </>
      )
    }

    return messages;
  }

	return (
    <>
      <div className={`${classes.transactionWidgetWrapper} w-500 `}>
        {
          renderMessageItems()
        }

      </div>
    </>
	)
}
