import React, {useEffect, useState, useRef} from 'react'
import {useHistory} from 'react-router-dom';
import { useCycle } from "framer-motion"
import { makeStyles } from '@material-ui/core/styles'

import getTheme from '../../themes'
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { uuid } from 'uuidv4';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { v4 as uuidv4 } from 'uuid';
import Claimer from "../../components/widgets/Claimer";
import RowPage from '../../components/UI/rowpage'
import Section from '../../components/UI/section'
import Cellar from "../../assets/images/cellar.jpg";
import Social from "../../assets/images/cellar-social.jpg";
import Cheers from "../../assets/images/cheers.jpg";

const useStyles = makeStyles(theme => ({
	titleBase: {
		color: theme.text.primary
	},
	textBase: {
		color: theme.text.secondary

	},
	titleSub: {
		color: theme.text.accentColor
	},

  claimDetails: {
    background: `linear-gradient(${theme.body.accent2Primaryl3}, ${theme.body.accent2Primaryl3}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,

  },
}))

export default function Landing(props) {
	const classes = useStyles()
	let theme = getTheme()

	let history = useHistory()


  useEffect(() => {


	},[])

	return (
    <>
			<RowPage
				state={props.state}
				connectWallet={() => {props.connectWallet()}}
				disconnectWallet={() => {props.disconnectWallet()}}
				address={props.state.address}
				web3State={props.state}
			>
				<Section
					number={1}
					hero={
						<>
							<div className={`flex flex-col`}>
								<div className={` ${classes.titleBase} text-4xl pt-12`}>
									Introducing Cloud Cellars
								</div>

								<div className={` ${classes.titleSub} text-2xl pb-12`}>
									For Rare Liquid Members Only
								</div>
							</div>
							<section className={`${classes.claimBox} flex flex-col flex-grow`}>
								{
									props.state.connected ?
										<Claimer status={true} state={props.state} setPendingList={(x) => {props.setPendingList(x)}}/>
									: <Claimer status={false} connectWallet={() => {props.connectWallet()}} history={null}  />
								}
							</section>
						</>
					}

					footer={
						<>

						<section className={`${classes.claimDetails} ${classes.titleBase} h-36 flex justify-center items-center`}>
							<div className={`flex text-xl md:text-4xl`}>
								<div>Quantity: 10K</div>
								<div className={`mx-6 md:mx-12`}>|</div>
								<div>Cost: Gas Only</div>
							</div>
						</section>
						</>
					}
				/>


		        <img className="mx-auto" src={Cellar} alt="Logo" />
				<Section number={2}>
					<div className={`md:w-full`}>
						<div className={` ${classes.titleBase} text-4xl pt-12`}>
							What is a Cloud Cellar?
						</div>

						<div className={`${classes.textBase} flex flex-col text-2xl flex-grow justify-start`}>
							<div className={`pt-12 pb-4`}>
								A sharable digital showcase of your physical wine and spirit collection stored and secured by Rare Liquid.
							</div>
							<div className={`pb-4`}>
								Cloud Cellar is a live application that allows the owner to login and customize the urls and appearance.  Appearance is derived from the Cloud Cellar NFT color palette.
							</div>
							<div className={`pb-4`}>
								Any user can like, comment, or bid on NFTs in your Cloud Cellar. A Cloud Cellar is necessary to participate in Rare Liquid’s physical wine and spirit drops.
							</div>
							<div className={`pb-4`}>
								Cloud Cellars earn a share of the governance RLIQ tokens over time.
							</div>
						</div>
					</div>
					<div className={`md:w-full`}>

		                <img className=" " src={Social} alt="Cellar" />
					</div>

				</Section>

				<Section number={3}>
					<div className={`w-full`}>
						<div className={` ${classes.titleBase} text-4xl pt-12`}>
							How do I get a Cloud Cellar?
						</div>

						<div className={`${classes.textBase} flex flex-col text-2xl flex-grow justify-start`}>
							<div className={`pt-12 pb-4`}>
								Cloud Cellars are included with Rare Liquid Society membership (RLS) NFTs and can be claimed here.
							</div>
							<div className={`pb-4`}>
								If you do not currently own a membership, you can buy on OpenSea or apply for a whitelist spot to purchase when the Rare Liquid Mint opens soon.
							</div>
						</div>
					</div>
					<div className={`md:w-full`}>

		                <img className=" " src={Cheers} alt="Cheers" />
					</div>
				</Section>


				<Section number={2}>
	        <div className={`${classes.cardContent} text-lg pt-12`}>
					<div id="FAQ" className={` ${classes.titleBase} text-4xl mb-24 flex items-center justify-center w-full `}>
						FAQs
					</div>

	         <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel2a-content"
	             id="panel2a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
						 Are there different varieties of Cloud Cellars?
	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	            <div className={`p-6 ${classes.cardContentSmall}`}>
                Yes.  Cloud Cellars are generative NFTs from an on-chain unique hash known as the Rare Liquid Identifier (RLID). Cloud Cellars have varying degrees of rare attributes by color, curvature, and bottle density. The quantity  of storage tokens included with each Cloud Cellar is influenced by the bottle density percentage.
				</div>
	           </AccordionDetails>
	         </Accordion>

	         <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
							 How do storage tokens work?
	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
						 Storage tokens are fungible tokens that represent the right to store a standard (750ml) bottle.  Storage tokens are burned and the storage period begins when members mint our drops and elect to have the physical bottle stored at a Rare Liquid facility.  When the storage period expires, owners may purchase additional storage tokens or redeem the bottle to drink it.
(subject to applicable regulations).

	           </div>
	          </AccordionDetails>
	         </Accordion>

	         <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
								Where is Rare Liquid’s first storage facility?
	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
							Rare Liquid is building a network of verified cold storage facilities and social spaces in the real world. The first is in the greater Los Angeles area. It functions as an insured storage facility for asset backed NFTS, a showcase for the Rare Liquid Index, and a tasting room for member events.
	           </div>
	          </AccordionDetails>
	         </Accordion>

	         <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
	             How do I use my Cloud Cellar?
	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
			   Once you have claimed your Cloud Cellar, it will be the home for your Rare Liquid Drops….NFTs backed curated bottles of rare wine and spirits. Physical bottles are safely stored with us until you are ready to drink, sell, or stake them. You will also be able to personalize and showcase any NFT you wish in your Cloud Cellar.
			   </div>
	          </AccordionDetails>
	         </Accordion>

	         <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
							 How do Rare Liquid Drops work?

	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
						 Curated wine and spirit releases from emerging and iconic artisans  are tokenized as asset backed NFTs for Rare Liquid members to acquire. Bottles can be redeemed for drinking, stored, or added to the Rare Liquid Index.
						 <br/>
						 <br/>
						 Artisans are selected by our team of master sommeliers, winemakers, distillers, and may be chosen by community vote.
						 <br/>
						 <br/>
						 The first Rare Liquid drop will be in Q2 with several additional drops in 2022.
						 <br/>
						 <br/>
						 We are featuring an emerging cult winemaker out of Paso Robles California for our first drop.
	           </div>
	          </AccordionDetails>
	         </Accordion>

	         <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
	             What is a Cult Winemaker?
	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
							A sold out and limited production artisan with a passionate following whose bottles can trade at multiples of retail on secondary wine markets.
	           </div>
	          </AccordionDetails>
	         </Accordion>

	         <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
	             What is  Rare Liquid Society? (RLS)
	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
						 Rare Liquid Society is the DAO and community that governs the Rare Liquid platform. Membership is designated by ownership of  <a target="_blank" className={classes.titleSub} href="https://opensea.io/collection/rareliquidsociety">Membership NFTs </a>.
	           </div>
	          </AccordionDetails>
	         </Accordion>

	         <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
	             What is the Rare Liquid Index?
	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
						 Rare Liquid is creating the first community-owned frictionless and composable wine and spirit index. The platform creates liquid markets for illiquid physical wine and spirits represented by Non-Fungible Tokens (NFTs).
The goal is to create the largest wine and spirit collection in the world while democratizing investment in that collection.

	            </div>
	          </AccordionDetails>
	         </Accordion>

	         <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
	             What if I need additional storage capacity to house the growing collection in my Cloud Cellar?
	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
							You may be able to purchase storage tokens on the secondary market or directly from Rare Liquid if available.
	            </div>
	          </AccordionDetails>
	         </Accordion>

					 <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
	             When can I claim my storage tokens?
	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
			        Storage tokens will be available to claim at the same time as our first physical drop.

						</div>
	          </AccordionDetails>
	         </Accordion>

					 <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
	             What are the benefits of Rare Liquid storage?
	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
			        Properly storing collectible wine and spirits to maximize flavor, longevity, and resale value requires holding at consistent conditions in cold storage. Rare Liquid houses your collection and provides a turnkey solution to verify provenance, frictionless transfer of ownership via NFT, and easy redemption when ready to drink.

						</div>
	          </AccordionDetails>
	         </Accordion>

					 <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
						 What is Provenance?

	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
			        Provenance of a wine is a view into its history and the conditions in which it was stored. This information is critical to assess the value of a bottle. Particularly for older wines that might be more fragile.

						</div>
	          </AccordionDetails>
	         </Accordion>

					 <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
						 Can I tokenize and add my home wine collection to my Cloud Cellar?

	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
			        At launch Cloud Cellars will only be able to store Rare Liquid Drops. Eventually owners will be able to scan and tokenize home collections as “unverified” bottles to showcase in Cloud Cellars.

						</div>
	          </AccordionDetails>
	         </Accordion>

					 <Accordion>
	           <AccordionSummary
	             expandIcon={<ExpandMoreIcon />}
	             aria-controls="panel3a-content"
	             id="panel3a-header"
	           >
	           <span className={`${classes.cardContentMed}`}>
	             What is a Rare Liquid Society Membership NFT? <a target="_blank" className={classes.titleSub} href="https://opensea.io/collection/rareliquidsociety"> (OpenSea)  </a>.
	           </span>
	           </AccordionSummary>
	           <AccordionDetails>
	           <div className={`p-6 ${classes.cardContentSmall}`}>
			        Rare Liquid Membership NFTs are a collection of 10K generative avatars, inspired by crypto and rare wine subcultures. They grant access to the utility of the Rare Liquid platform and community.
							</div>
	          </AccordionDetails>
	         </Accordion>

					 <Accordion className={`bg-white`}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<span className={`${classes.cardContentMed}`}>
							  Benefits of Ownership Include
							</span>
						</AccordionSummary>
						<AccordionDetails>
							<ul className={`p-6 ${classes.cardContentSmall}`}>
							 <li>- Cloud Cellars </li>
							 <li>- RLIQ governance token distribution</li>
							 <li>- Whitelisting for Rare Liquid Drops </li>
							 <li>- Tasting Events (IRL and Virtual)</li>
							 <li>- Discord Channels</li>
							 <li>- Merch Drops</li>
						 </ul>

						 <div className={`p-6 ${classes.cardContentSmall}`}>
						 1000 membership NFTs, representing 10% of the maximum, were minted during a sold out presale in September of 2021 to seed the project.
The remaining 9000 membership NFTs from the founding collection will be minted when the rare liquid mint reopens soon.

							</div>
						</AccordionDetails>
					</Accordion>

					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel3a-content"
							id="panel3a-header"
						>
						<span className={`${classes.cardContentMed}`}>
							Do I need a crypto wallet to purchase a Membership NFT or can I pay with a Credit Card?
						</span>
						</AccordionSummary>
						<AccordionDetails>
							<div className={`p-6 ${classes.cardContentSmall}`}>
								Minting Membership NFTs requires using a supported crypto wallet with a sufficient Ethereum balance. If you do not currently have a crypto wallet follow these simple instructions.  If you need help visit the Wallet support channel in our Discord.
								<br/>
								<br/>
								Never give your seed phrase to anyone…ever…unless you trust them deeply. If you do they tNobody from Rare Liquid will ever ask for your seed phrase.
						 </div>
					 </AccordionDetails>
					</Accordion>

					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel3a-content"
							id="panel3a-header"
						>
						<span className={`${classes.cardContentMed}`}>
						When do we get to drink?

						</span>
						</AccordionSummary>
						<AccordionDetails>
							<div className={`p-6 ${classes.cardContentSmall}`}>
								Rare Liquid’s asset backed NFTs can be redeemed for consumption by owners at any time based on location and local shipping laws. Redemption requires full age and identity verification. Rare Liquid will also be hosting members only virtual and IRL tasting events.
						 </div>
					 </AccordionDetails>
					</Accordion>

					<Accordion className={`bg-white`}>
					 <AccordionSummary
						 expandIcon={<ExpandMoreIcon />}
						 aria-controls="panel1a-content"
						 id="panel1a-header"
					 >
						 <span className={`${classes.cardContentMed}`}>
							 Benefits of Ownership Include
						 </span>
					 </AccordionSummary>
					 <AccordionDetails>
						 <ul className={`p-6 ${classes.cardContentSmall}`}>
							<li>- Purchasing investment grade wine and spirits to contribute to the community owned index. </li>
							<li>- Hiring of dedicated team </li>
							<li>- DAO Treasury Seeding</li>
							<li>- Sourcing for Rare Liquids and content to support Physical Drops </li>
							<li>- Membership NFT artist revenue share</li>
							<li>- Storage Expansion</li>
							<li>- Insurance of member assets in custody</li>
							<li>- Repayment of Start-Up Debt</li>
							<li>- Sales Taxes from Drops</li>
							<li>- Fees related to ABC and SEC compliance and counsel</li>
							<li>- Philanthropy</li>
						</ul>
					 </AccordionDetails>
				 </Accordion>

				 <Accordion>
					 <AccordionSummary
						 expandIcon={<ExpandMoreIcon />}
						 aria-controls="panel3a-content"
						 id="panel3a-header"
					 >
					 <span className={`${classes.cardContentMed}`}>
					 What are asset backed NFTs?


					 </span>
					 </AccordionSummary>
					 <AccordionDetails>
						 <div className={`p-6 ${classes.cardContentSmall}`}>
						 	NFTs are Non Fungible Tokens that use blockchain technology to create something that is unique and ownable in the digital world. To date, most NFTs are purely digital but Rare Liquid is part of the next generation of NFTs which will function as deeds of ownership and digital twins to physical investment grade collectibles.
						</div>
					</AccordionDetails>
				 </Accordion>
				 <Accordion>
					 <AccordionSummary
						 expandIcon={<ExpandMoreIcon />}
						 aria-controls="panel3a-content"
						 id="panel3a-header"
					 >
					 <span className={`${classes.cardContentMed}`}>
					 What is a DAO?


					 </span>
					 </AccordionSummary>
					 <AccordionDetails>
						 <div className={`p-6 ${classes.cardContentSmall}`}>
DAO stands for “Decentralized Autonomous Organization.” DAOs are typically governed by community vote. DAOs form when small groups of developers, creators, and investors come together around a shared vision. They pool skills, networks, and capital to build self-governing technology-enabled businesses and communities.
						</div>
					</AccordionDetails>
				 </Accordion>
	        </div>
				</Section>
			</RowPage>
    </>
	)
}
