import baseTheme from './base'
import DMMono from "../assets/fonts/dmmono_med.ttf";

// main
const basePageColor = "#ffffff"
const backgroundAccent = "#fbfbfb"
const borderAccent = "#a1a1a1"
const borderAccent2 = "#f5f5f5"

const colorAccent = '#1c8b95'
const colorSecondary = '#9AFFFD'

const textPrimary = '#000000'
const textSecondary = '#474747'
const textTertiary = '#aaaaaa'
const textLowlight = '#000000'
const lightRed = '#ff5f5f';

const accent = `#72c1cc`
const accent2 = `#dd529b`
const accentRed = `#E71D36`
const accentGreen = `#00F0B5`

const backgroundPrimaryl0 = `${basePageColor}11`
const backgroundPrimaryl1 = `${basePageColor}31`
const backgroundPrimaryl2 = `${basePageColor}64`
const backgroundPrimaryl3 = `${basePageColor}94`
const backgroundPrimaryl4 = `${basePageColor}ff`

const accentPrimaryl = `${accent}`
const accentPrimaryl0 = `${accent}11`
const accentPrimaryl1 = `${accent}31`
const accentPrimaryl2 = `${accent}64`
const accentPrimaryl3 = `${accent}94`
const accentPrimaryl4 = `${accent}ff`

const accent2Primaryl = `${accent2}`
const accent2Primaryl0 = `${accent2}24`
const accent2Primaryl1 = `${accent2}44`
const accent2Primaryl2 = `${accent2}64`
const accent2Primaryl3 = `${accent2}84`
const accent2Primaryl4 = `${accent2}ff`


const poolableGradient = "radial-gradient(circle farthest-corner at 10% 20%, rgba(190,255,255,1) 0%, rgba(255,150,255,0.3) 100.2%)"
const poolableGradientWebkit = "linear-gradient(#72c1cc, #dd529b)"
const poolableGradientText = "linear-gradient(#00e0ff, #f77bff)"

const poolableGradientTrans = `linear-gradient(${accentPrimaryl4}, ${accent2Primaryl4}), linear-gradient(${basePageColor}, ${basePageColor})`
const poolableGradientWebkitTrans2 = `linear-gradient(${accentPrimaryl4}, ${accent2Primaryl4}), linear-gradient(${basePageColor}, ${basePageColor})`

const accentRedl2 = `${accentRed}64`
const accentRedl4 = `${accentRed}ff`

const whiteAccent = `#dcdcdc`
const radialBackgroundUpperLeft = `#041323`
const radialBackgroundLowerRight = `#040623`

const colorl = `${colorAccent}`
const colorl0 = `${colorAccent}24`
const colorl1 = `${colorAccent}44`
const colorl2 = `${colorAccent}64`
const colorl3 = `${colorAccent}84`
const colorl4 = `${colorAccent}ff`

const bgColor1 = '#000000'
const bgColor3 = '#3F4456'
const bgColor4 = '#3B4053'
const bgColor5 = '#171D31'
const accentTrans = `${colorSecondary}64`
const accentTrans2 = `${colorSecondary}84`

export const textFont = {
	fontFamily: 'DMMono',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `
    local('DMMono'),
    local('DMMono'),
    url(${DMMono}) format('truetype')
  `,
}
baseTheme.overrides["MuiCssBaseline"] = {
	'@global': {
		'@font-face': [textFont],

		body: {
			backgroundColor: basePageColor,
		},
		".web3modal-modal-card": {
			borderRadius: 0,
			background: poolableGradient
		},
		".web3modal-provider-container": {
			borderRadius: 0,
			"&:hover": {
        background:  poolableGradient
      }
		},

		"*::-webkit-scrollbar": {
			width: 10,
			backgroundColor: `#ffffff`
		},
		"*::-webkit-scrollbar-track": {
			"-webkit-box-shadow": `inset 0 0 6px rgba(0,0,0,0.3)`,
			backgroundColor: `#eeeeee`
		},
		"*::-webkit-scrollbar-thumb": {
			"-webkit-box-shadow": `outset 0 0 6px rgba(0,0,0,.3)`,
			backgroundColor: "#fafafa"
		}
	}
}
const theme = {
	...baseTheme,

	palette: {
		...baseTheme.palette,
		background: {
			default: basePageColor
		},
		primary: {
			...baseTheme.palette.primary,
			main: colorAccent,
		},
		secondary: {
			...baseTheme.palette.secondary,
			main: colorSecondary,
		}
	},
	body: {
		basePageColor: basePageColor,
		backgroundAccent: backgroundAccent,
		heroBackground: `black`,
		borderAccent: borderAccent,
		borderAccent2: borderAccent2,
		accentTrans: accentTrans,
		darkAccent: accent,
		accent: accent,

		backgroundPrimaryl1: backgroundPrimaryl1,
		backgroundPrimaryl2: backgroundPrimaryl2,
		backgroundPrimaryl3: backgroundPrimaryl3,
		backgroundPrimaryl4: backgroundPrimaryl4,
		backgroundPrimaryl0: backgroundPrimaryl0,

		accentPrimaryl: accentPrimaryl,
		accentPrimaryl0: accentPrimaryl0,
		accentPrimaryl1: accentPrimaryl1,
		accentPrimaryl2: accentPrimaryl2,
		accentPrimaryl3: accentPrimaryl3,
		accentPrimaryl4: accentPrimaryl4,

		accent2Primaryl: accent2Primaryl,
		accent2Primaryl0: accent2Primaryl0,
		accent2Primaryl1: accent2Primaryl1,
		accent2Primaryl2: accent2Primaryl2,
		accent2Primaryl3: accent2Primaryl3,
		accent2Primaryl4: accent2Primaryl4,

		gradient: poolableGradient,
		poolableGradientWebkit: poolableGradientText,
		poolableGradientTrans: poolableGradientTrans,
		poolableGradientText: poolableGradientText,
		poolableGradientWebkitTrans2: poolableGradientWebkitTrans2,
		colorl: colorl,
		colorl0: colorl0,
		colorl1: colorl1,
		colorl2: colorl2,
		colorl3: colorl3,
		colorl4: colorl4,
		lightAccent: basePageColor,
		whiteAccent: whiteAccent,
		accentGreen: accentGreen,
		accentGreen4: accentGreen,

		accentRed: accentRed,
		accentRedl2: accentRedl2,
		accentRedl4: accentRedl4,
		colorAccent: colorAccent,
		insetShadow: `inset 0px -1px 4px 0px rgb(207 207 207)`
	},
	border: {},
	buttons: {
		...baseTheme.buttons,
		outlined: {
			...baseTheme.buttons.outlined,
			default: {
				...baseTheme.buttons.outlined.default,
				color: textPrimary,
			}
		}
	},
	card: {
		header: {
			backgroundColor: bgColor5,
			color: textSecondary,
		}
	},
	environments: {
		...baseTheme.environments,
		card: {
			backgroundColor: '#3C4153',
		},
	},
	imageUploader: {
		backgroundColor: bgColor3,
	},
	modal: {
		backgroundColor: bgColor1,
	},
	overrides: {
		...baseTheme.overrides,
		MuiCheckbox: {
			...baseTheme.overrides.MuiCheckbox,
			colorSecondary: {
				'&.Mui-disabled': {
					color: 'rgba(255,255,255,0.25)'
				},
			},
			root: {
				...baseTheme.overrides.MuiCheckbox.root,
				color: textPrimary
			}
		},
		MuiFormControlLabel: {
			label: {
				'&.Mui-disabled': {
					color: textSecondary
				}
			}
		},
		MuiFormHelperText: {
			root: {
				color: textSecondary,
				'&.Mui-error': {
					color: colorAccent,
				}
			},
		},
		MuiFormLabel: {
			root: {
				color: textPrimary,
				'&.Mui-disabled': {
					color: textSecondary,
				},
				'&.Mui-focused': {
					color: colorSecondary,
				},
				'&.Mui-error': {
					color: colorAccent,
				},
			},
		},
		MuiIconButton: {
			root: {
				color: textSecondary,
			},
		},
		MuiInput: {
			underline: {
				'&:before': {
					borderBottomColor: textSecondary,
				},
				'&:after': {
					borderBottomColor: textSecondary,
				},
				'&:hover:before': {
					borderBottomColor: [textSecondary, '!important'],
				},
				'&.Mui-error:after': {
					borderBottomColor: colorAccent,
				},
			},
			root: {
				color: textPrimary,
				'&:hover': {
					borderBottom: 'none'
				},
				'& .Mui-disabled': {
					color: textPrimary,
				},
			},
		},
		MuiInputAdornment: {
			root: {
				color: textPrimary,
			}
		},
		MuiPaper: {
			root: {
				backgroundColor: bgColor1,
				color: textPrimary,
			}
		},
		MuiPickersDay: {
			day: {
				color: textPrimary,
			},
		},
		MuiPickersCalendarHeader: {
			dayLabel: {
				color: textSecondary,
			},
			iconButton: {
				backgroundColor: 'none'
			}
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: bgColor5,
			}
		},
		MuiPopover: {
			paper: {
				backgroundColor: bgColor1,
			},
		},
		MuiSelect: {
			icon: {
				color: textPrimary
			}
		},
		MuiTypography: {
			colorTextSecondary: {
				color: textSecondary
			}
		}
	},
	select: {
		backgroundColor: bgColor4,
	},
	table: {
		backgroundColor: 'transparent',
		actions: {
			color: '#C9C9C9'
		},
		header: {},
		highlight: {
			backgroundColor: bgColor5
		},
		rows: {
			backgroundColor: bgColor5
		}
	},
	tabs: {
		indicator: colorAccent,
		buttonTabs: {
			root: {
				backgroundColor: bgColor4,
				color: '#666'
			},
			selected: {
				backgroundColor: '#DADBDF'
			}
		}
	},
	text: {
		primary: textPrimary,
		secondary: textSecondary,
		tertiary: textTertiary,
		accentColor: accent,

		poolableGradient: poolableGradient,
		poolableGradientWebkit: poolableGradientWebkit
	},
}

export default theme
