import React, {useEffect, useState} from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../../themes'

const useStyles = makeStyles(theme => ({

	web3Connector: {
		color: `${theme.text.primary}`,
    background: `linear-gradient(${theme.body.accentPrimaryl2}, ${theme.body.accentPrimaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,

		"&:hover": {
      color: `${theme.text.dark}`,

			cursor: "pointer",
      background: `linear-gradient(${theme.body.accentPrimaryl4}, ${theme.body.accentPrimaryl4}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,

		}
		// fontSize: 55
	},
  gradient: {
		borderWidth: 2,
		position: "relative",
		borderImage: `${theme.body.gradient} 1`,
	}
}))

export default function Web3Connecter(props) {
	const classes = useStyles()
	const [state, setState] = useState({
    address: null,
		connected: false,
		eth: 0
  });

	useEffect(() => {
		if(props["web3State"]) {
			if (props.web3State.connected) {
				props.web3State.web3.eth.getBalance(props.web3State.address).then((bal) => {
					setState({
						address: props.web3State.address,
						connected: props.web3State.connected,
						eth: parseFloat(props.web3State.web3.utils.fromWei(bal)).toFixed(4)
					})
				})
			} else {
				setState({
					connected: false
				})
			}

		} else {
			setState({
				connected: false
			})
		}
	},[props.web3State, props.address])

	return (
		<>
      <div className={` py-12 md:py-0 md:px-8 md:px-0 pl-6 flex flex-col md:flex-row text-sm minted-gradient-text h-full items-center justify-between `}>
				{
					state.connected ?
						<>
							<div className={`${classes.borders} flex px-1 md:px-6 md:mr-6 h-full items-center my-1 md:my-4`}>
								<div className={`flex items-center justify-center`}>
									<Jazzicon diameter={15} seed={jsNumberForAddress(state.address)} />
									<span className={`px-4`}>{state.address.slice(0,4) + "...." + state.address.substr(-4)}</span>
									&#8226;
								</div>
								<div className={`px-4`}>{state.eth} ETH</div>
							</div>
							<button className={`${classes.web3Connector} font-thin px-4 py-2 text-xl`} onClick={() => {props.disconnectWallet()}}>
								Disconnect
							</button>
						</>
					:
						<>
							<button className={`${classes.web3Connector} font-thin px-4 py-2 text-xl`} id={`connect_wallet`} onClick={() => {props.connectWallet()}}>
								Connect
							</button>
						</>
				}
      </div>
    </>
	)
}
