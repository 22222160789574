import React, {useEffect, useState} from 'react'
import Navbar from "../widgets/Navbar"

import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'

const useStyles = makeStyles(theme => ({
  basePage: {
    background: `linear-gradient(${theme.body.backgroundPrimaryl4}, ${theme.body.backgroundPrimaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
    height: "calc(100%)",

    overflowX: "hidden",
    overflowY: "auto",
  },
}))

export default function RowPage(props) {
	const classes = useStyles()

	return (
    <>
      <main className={`${classes.basePage} w-full`}>
        <Navbar
          app={true}
          state={props.state}
          updateState={(e) => {props.updateState(e)}}
          connectWallet={() => {props.connectWallet()}}
          disconnectWallet={() => {props.disconnectWallet()}}
          address={props.state.address}
          web3State={props.state}
          history={props.history}
        />

        <div className={``}>
          {props.children}
        </div>
      </main>
    </>
	)
}
