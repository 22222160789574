const getConfig = () => {
	const local = {
		apis: {
			azmth: {
        base: 'http://127.0.0.1:8080',
				opensea: 'https://testnets.opensea.io/collection/azmth-ef6xqhgzgk',
				contract: '0xd2b2fA4DF4CbB22B23D283A2e30521a2d2D0CD6D',
				etherscan: 'https://rinkeby.etherscan.io/token/'
			}
		},

	}
	const dev = {
    apis: {
      azmth: {
        base: 'http://127.0.0.1:8080',
				opensea: 'https://testnets.opensea.io/collection/azmth-ef6xqhgzgk',
				contract: '0xd2b2fA4DF4CbB22B23D283A2e30521a2d2D0CD6D',
				etherscan: 'https://rinkeby.etherscan.io/token/'

      }
    },

	}

	const prod = {
    apis: {
      azmth: {
				base: 'https://azmth-server-qa9hl.ondigitalocean.app',
				opensea: 'https://opensea.io/collection/azmth',
				contract: '0xeE30b1318dD4a860261D521D226b2313425246E1',
				etherscan: 'https://etherscan.io/token/'

      }
    },

	}

	switch(process.env.REACT_APP_CLUSTER) {
		case 'local': return local
		case 'dev': return dev
		case 'prod': return prod
		default: return dev
	}
}

const config = getConfig()

export default config
