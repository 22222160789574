import React from 'react';
import { Provider } from 'react-redux'
import { hydrate, render } from 'react-dom';
import ReactDOM from 'react-dom';
import configureStore, { history } from './store/configureStore'
import StarfieldAnimation from 'react-starfield-animation'

import './index.css';
import App from './components/App';

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
      <App history={history} />
  </Provider>,

  document.getElementById('root')
);
