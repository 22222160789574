import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import { motion,AnimatePresence } from 'framer-motion';
import { useSpring, animated } from 'react-spring'

import { v4 as uuidv4 } from 'uuid';
import { HashLink } from 'react-router-hash-link';

import Drawer from '@material-ui/core/Drawer';
import StorefrontIcon from '@material-ui/icons/Storefront';
import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Web3Connecter from "./web3connector"
import Popover from '@material-ui/core/Popover';
import config from '../../services/config.js'
import TwitterLink from "../../assets/twitterlink.png";
import DiscordLink from "../../assets/discord.png";
import MediumLink from "../../assets/med.png";
import GitbookLink from "../../assets/gitbook.png";
import OpenSeaLink from "../../assets/OpenSea.png";

const opensea = config.apis.azmth.opensea
const etherscan = config.apis.azmth.etherscan
const contract = config.apis.azmth.contract

const useStyles = makeStyles(theme => ({
  baseBar: {
    zIndex: 100,
    height: 75,
    color: theme.text.secondary,
    background: `linear-gradient(${theme.body.backgroundPrimaryl0}, ${theme.body.backgroundPrimaryl0}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
  },
  backButton: {
    width: 50,
    height: 50,
    left: 0,
    color: theme.text.secondary,
    // backgroundColor: theme.body.basePageColor,
    outline: "none !important",
    marginRight: 300
  },
  hederaBalanceWrapper: {
    outline: "none !important"
  },
  logoutWrapper: {
    outline: "none !important",
    color: theme.text.secondary,

  },
  hederaIconWrapper: {
    height: "30px",
    width: '30px',
  },
  balancePopup: {
    bottom: 0,
    right: 0,
    backgroundColor: "white"
  },
  drawerButton: {
    color: theme.text.accentColorDim,
    zIndex: 100000
  },
  drawerPaper: {
    width: "75%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    // backgroundColor: theme.body.accent2Primaryl0,
    border: `none !important`,
    background: `linear-gradient(${theme.body.backgroundPrimaryl4}, ${theme.body.backgroundPrimaryl1}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
    height: "100%"
  },
  borders: {
    borderLeft: `2px solid ${theme.body.borderAccent}`,
    borderRight: `2px solid ${theme.body.borderAccent}`
  },
  popoverRootRoot: {
    width: `300px !important`,
    height: `500px !important`,
    top: `74px !important`,
    left: `100px !important`,

  },
  root: {
    top: `74px !important`,
    left: `200px !important`,

    width: `300px !important`,
    height: `500px !important`,
  },
  popoverRoot: {
    width: `300px !important`,
    height: `500px !important`,
    },
  popoverPaper: {
    background: `linear-gradient(${theme.body.accent2Primaryl2}, ${theme.body.accent2Primaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
    borderRadius: `0px !important`,
    left: `155px !important`,
    top: `0px !important`,

  }
}))

export default function Navbar(props) {
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('currentUser')) || null)

	const classes = useStyles()
  const history = useHistory()
  const [state, setState] = useState(false);
  const [wDState, setWDState] = useState(false);
  const [loginDrawerState, setLoginDrawerState] = useState(false);
  const [sliderDrawer, setSliderDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [isDarkMode, setDarkMode] = React.useState(props.theme !== "DARK" ? false : true);

  // const toggleDarkMode = () => {
  //   let newTheme = !isDarkMode
  //   setDarkMode(newTheme);
  //   props.updateTheme(!newTheme ? "LIGHT" : "DARK")
  // };

  const wDSet = (user) => {
    setWDState(false);
    // props.updateUser(user)
  }

  const numberWithCommas = (x) =>{
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const goToDiscord = () => {
    window.location.href = "https://discord.gg/UbXjk7C759";
  }

  const goFAQ = () => {
    window.location.href = "https://rareliquid.gitbook.io/rareliquid/";
  }

  const goOpenSea = () => {
    window.location.href = "https://opensea.io/collection/cloud-cellar";
  }

  const goTwitter = () => {
    window.location.href = "https://twitter.com/rareliquidfi";
  }

  const goHome = () => {
    history.push("/")
  }

  const goRoadmap = () => {
    history.push("/roadmap")
  }

  const goDistribution = () => {
    history.push("/distribution")
  }

  const logout = () => {
    localStorage.setItem('currentUser', null)
    props.updateUser(null)
    history.push("/")
  }

  const toggleSliderDrawer = (open) => (event) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    // 	return;
    // }
    setSliderDrawer(open)
  };

  const getOpenseaLink = () => {

  }

	return (
		<>
    <div className={`${classes.baseBar} w-full h-auto relative `}>
        <div className={` visible md:invisible absolute top-0 left-0 z-index`}>
          <button className={`m-4 w-12 h-12 text-xl ${classes.drawerButton}`} onClick={toggleSliderDrawer( true)}>
            <MenuIcon fontSize={`large`} />
          </button>
          <SwipeableDrawer anchor={`left`} open={sliderDrawer}  onClose={toggleSliderDrawer(false)} onOpen={toggleSliderDrawer(true)} classes={{
            paper: classes.drawerPaper,
          }}>
            <div className={`w-auto flex justify-center items-center`}>
              <Web3Connecter web3State={props.web3State} disconnectWallet={() => {props.disconnectWallet()}} connectWallet={() => {props.connectWallet()}} address={props.address}/>
            </div>
              <div className={`w-0 md:w-auto invisible md:visible ${classes.welcomeDiv} flex-shrink md:flex-grow`}></div>

              <motion.button   whileTap={{ scale: 1.03 }} className={`${classes.logoutWrapper} h-12 text-xl font-thin flex justify-between items-center pl-6`}  aria-controls="simple-menu" aria-haspopup="true" onClick={() => {goHome()}}>
                Home
              </motion.button >
              <HashLink to="#FAQ">FAQ</HashLink>
						<a href="https://twitter.com/rareliquidfi" target="_blank" class="justify-end inline-block mr-2 md:block flex items-center p-2">
							<img src={TwitterLink} className={"social-icons"} width={"35px"} />
						</a>
						<a href="https://discord.com/invite/UbXjk7C759" target="_blank" class=" inline-block mr-2 md:block flex items-center p-2">
							<img src={DiscordLink} className={"social-icons"} width={"35px"} />
						</a>
						<a href="https://rareliquid.gitbook.io/rareliquid/" target="_blank" class=" inline-block mr-2 md:block flex items-center p-2">
							<img src={GitbookLink} className={"social-icons"} width={"35px"} />
						</a>
						<a href="https://opensea.io/collection/cloud-cellar" target="_blank" class=" inline-block mr-2 md:block flex items-center p-2">
							<img src={OpenSeaLink} width={"35px"} />
						</a>
          </SwipeableDrawer>
        </div>

        <div className={`${classes.baseBar} md:w-full right-0 px-6 md:visible invisible w-0 mx-auto`} key={'top'}>
          <div className={`justify-around md:justify-center md:px-12 h-full flex text-xl items-center w-full ${classes.barHeight}`}>
              <motion.button   whileTap={{ scale: 1.03 }} className={`${classes.logoutWrapper} h-full font-thin flex justify-between items-center pr-6`}  aria-controls="simple-menu" aria-haspopup="true" onClick={() => {goHome()}}>
                Home
              </motion.button >
              <HashLink to="#FAQ">FAQ</HashLink>

              <div className={`w-0 md:w-auto invisible md:visible ${classes.welcomeDiv} flex-shrink md:flex-grow`}></div>

              <div className={`w-auto flex justify-start md:justify-center items-center h-full`}>
						<a href="https://twitter.com/rareliquidfi" target="_blank" class="justify-end inline-block mr-2 md:block flex items-center p-2">
							<img src={TwitterLink} className={"social-icons"} width={"35px"} />
						</a>
						<a href="https://discord.com/invite/UbXjk7C759" target="_blank" class=" inline-block mr-2 md:block flex items-center p-2">
							<img src={DiscordLink} className={"social-icons"} width={"35px"} />
						</a>
						<a href="https://rareliquid.gitbook.io/rareliquid/" target="_blank" class=" inline-block mr-2 md:block flex items-center p-2">
							<img src={GitbookLink} className={"social-icons"} width={"35px"} />
						</a>
						<a href="https://opensea.io/collection/cloud-cellar" target="_blank" class=" inline-block mr-2 md:block flex items-center p-2">
							<img src={OpenSeaLink} width={"35px"} />
						</a>
                <Web3Connecter theme={isDarkMode} web3State={props.web3State} disconnectWallet={() => {props.disconnectWallet()}} connectWallet={() => {props.connectWallet()}} address={props.address}/>
              </div>
          </div>
        </div>
      </div>
    </>
	)
}

// AFTER WALLET BUTTON IN MINIMIZE IF WE WANT IT
// <div className={`flex-grow`}>
//   <AnimatedIcon theme={props.theme} updateTheme={(t) => props.updateTheme(t)}/>
// </div>

// AFTER WEB CONNECTOR IF WE WANT IT
// <div className={`pl-6`}>
//   <AnimatedIcon theme={props.theme} updateTheme={(t) => props.updateTheme(t)}/>
// </div>

// <Authenticator />
