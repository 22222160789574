import baseTheme from './base'
import BebasNeue from "../assets/fonts/BebasNeue-Regular.ttf";

const textPrimary = "#f7f7f7"
const textSecondary = "#b5b5b5"
const basePageColor = "#0f0f0f"
const darkBarColor = "#0a0a0a"
const backgroundAccent = "#141414"
const accent = "#e0ff17"
const accent2 = "#24e9ff"

const backgroundPrimaryl0 = `${backgroundAccent}11`
const backgroundPrimaryl1 = `${backgroundAccent}31`
const backgroundPrimaryl2 = `${backgroundAccent}64`
const backgroundPrimaryl3 = `${backgroundAccent}94`
const backgroundPrimaryl4 = `${backgroundAccent}ff`

const accentPrimaryl = `${accent}`
const accentPrimaryl0 = `${accent}11`
const accentPrimaryl1 = `${accent}31`
const accentPrimaryl2 = `${accent}64`
const accentPrimaryl3 = `${accent}94`
const accentPrimaryl4 = `${accent}ff`


const accent2Primaryl = `${accent2}`
const accent2Primaryl0 = `${accent2}11`
const accent2Primaryl1 = `${accent2}31`
const accent2Primaryl2 = `${accent2}64`
const accent2Primaryl3 = `${accent2}94`
const accent2Primaryl4 = `${accent2}ff`


const transparentLayer0 = `#ffffff11`
const transparentLayer2 = `#ffffff31`
const transparentLayer3 = `#ffffff41`
const transparentLayer4 = `#ffffff51`
const transparentLayer5 = `#fffffff1`


const whiteAccent = `#dcdcdc`

export const textFont = {
	fontFamily: 'BebasNeue',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `
    local('BebasNeue'),
    local('BebasNeue'),
    url(${BebasNeue}) format('truetype')
  `,
}
baseTheme.overrides["MuiCssBaseline"] = {
	'@global': {
		'@font-face': [textFont],

		body: {
			backgroundColor: basePageColor,
			// background: `linear-gradient(315deg,#050817, #030b15)`,
			// backgroundRepeat: "no-repeat",
			// backgroundAttachment: "fixed",
			// "&:after": {
			//  position: "absolute",
			//  content: "''",
			//  top: -250,
			//  left: -250,
			//  width: 1500,
			//  height: 1000,
			//  background: `radial-gradient(${radialBackgroundUpperLeft}, transparent, transparent)`,
			// },
			// "&:before": {
			//  position: "absolute",
			//  content: "''",
			//  bottom: -250,
			//  right: -250,
			//  width: 1000,
			//  height: 1500,
			//  background: `radial-gradient(${radialBackgroundLowerRight}, transparent, transparent)`,
			// }
		},
		".web3modal-modal-card": {
			background: `linear-gradient(${backgroundPrimaryl1}, ${backgroundPrimaryl1}), linear-gradient(${basePageColor}, ${basePageColor})`,
			borderRadius: 0
		},
		".web3modal-provider-name":{
			color: textPrimary
		},
		".web3modal-provider-container": {
			borderRadius: 0,
			background: `linear-gradient(${backgroundPrimaryl1}, ${backgroundPrimaryl1}), linear-gradient(${basePageColor}, ${basePageColor})`,

			"&:hover": {
				background: `linear-gradient(${backgroundPrimaryl4}, ${backgroundPrimaryl4}), linear-gradient(${basePageColor}, ${basePageColor})`,
			}
		},
		"*::-webkit-scrollbar": {
			width: 10,
			backgroundColor: `#F5F5F5`
		},
		"*::-webkit-scrollbar-track": {
			"-webkit-box-shadow": `inset 0 0 6px rgba(0,0,0,0.3)`,
			backgroundColor: `#111419`
		},
		"*::-webkit-scrollbar-thumb": {
			"-webkit-box-shadow": `inset 0 0 6px rgba(0,0,0,.3)`,
			backgroundColor: `#1b1f27`
		},
	}
}
const theme = {
	...baseTheme,

	palette: {
		...baseTheme.palette,
		background: {
			default: basePageColor
		},
		primary: {
			...baseTheme.palette.primary,
			main: accent,
		},
		secondary: {
			...baseTheme.palette.secondary,
			main: accent,
		}
	},
	body: {
		basePageColor: basePageColor,
		backgroundAccent: backgroundAccent,
		darkBarColor: darkBarColor,
		heroBackground: `black`,

		backgroundPrimaryl1: backgroundPrimaryl1,
		backgroundPrimaryl2: backgroundPrimaryl2,
		backgroundPrimaryl3: backgroundPrimaryl3,
		backgroundPrimaryl4: backgroundPrimaryl4,
		backgroundPrimaryl0: backgroundPrimaryl0,

		accentPrimaryl: accentPrimaryl,
		accentPrimaryl0: accentPrimaryl0,
		accentPrimaryl1: accentPrimaryl1,
		accentPrimaryl2: accentPrimaryl2,
		accentPrimaryl3: accentPrimaryl3,
		accentPrimaryl4: accentPrimaryl4,

		accent2Primaryl: accent2Primaryl,
		accent2Primaryl0: accent2Primaryl0,
		accent2Primaryl1: accent2Primaryl1,
		accent2Primaryl2: accent2Primaryl2,
		accent2Primaryl3: accent2Primaryl3,
		accent2Primaryl4: accent2Primaryl4,

	},
	border: {},
	buttons: {
		...baseTheme.buttons,
		outlined: {
			...baseTheme.buttons.outlined,
			default: {
				...baseTheme.buttons.outlined.default,
				color: textPrimary,
			}
		}
	},
	card: {
		header: {
			// backgroundColor: bgColor5,
			color: textSecondary,
		}
	},
	environments: {
		...baseTheme.environments,
		card: {
			backgroundColor: '#3C4153',
		},
	},
	imageUploader: {
		// backgroundColor: bgColor3,
	},
	modal: {
		// backgroundColor: bgColor1,
	},
	overrides: {
		...baseTheme.overrides,
		Mui: {
			expanded: {
				background: `linear-gradient(${accent2Primaryl2}, ${accent2Primaryl2}), linear-gradient(${basePageColor}, ${basePageColor})`,

			}
		},
		MuiAccordion: {
			root: {
				background: `linear-gradient(${accent2Primaryl1}, ${accent2Primaryl1}), linear-gradient(${basePageColor}, ${basePageColor})`,

				"&:hover": {
					background: `linear-gradient(${accent2Primaryl2}, ${accent2Primaryl2}), linear-gradient(${basePageColor}, ${basePageColor})`,

				}
			},
			expanded: {
				background: `linear-gradient(${accent2Primaryl2}, ${accent2Primaryl2}), linear-gradient(${basePageColor}, ${basePageColor})`,
			},
		},
		MuiCheckbox: {
			...baseTheme.overrides.MuiCheckbox,
			colorMint: {
				'&.Mui-disabled': {
					color: 'rgba(255,255,255,0.25)'
				},
			},
			root: {
				...baseTheme.overrides.MuiCheckbox.root,
				color: textPrimary
			}
		},
		MuiFormControlLabel: {
			label: {
				'&.Mui-disabled': {
					// color: textSecondary
				}
			}
		},
		MuiFormHelperText: {
			root: {
				color: textSecondary,
				'&.Mui-error': {
					// color: colorAccent,
				}
			},
		},
		MuiFormLabel: {
			root: {
				color: textPrimary,
				'&.Mui-disabled': {
					color: textSecondary,
				},
				'&.Mui-focused': {
					// color: colorMint,
				},
				'&.Mui-error': {
					// color: colorAccent,
				},
			},
		},
		MuiIconButton: {
			root: {
				color: textSecondary,
			},
		},
		MuiInput: {
			underline: {
				'&:before': {
					borderBottomColor: textSecondary,
				},
				'&:after': {
					borderBottomColor: textSecondary,
				},
				'&:hover:before': {
					borderBottomColor: [textSecondary, '!important'],
				},
				'&.Mui-error:after': {
					// borderBottomColor: colorAccent,
				},
			},
			root: {
				color: textPrimary,
				'&:hover': {
					borderBottom: 'none'
				},
				'& .Mui-disabled': {
					color: textPrimary,
				},
			},
		},
		MuiInputAdornment: {
			root: {
				color: textPrimary,
			}
		},
		MuiPaper: {
			root: {
				backgroundColor: `transparent`,
				color: textPrimary,
				// border: `1px solid ${textTertiary}`,
				'&:hover': {
					transition: `.2`,
					// border: `1px solid ${textTertiaryHover}`,
				}
			}
		},
		MuiPickersDay: {
			day: {
				color: textPrimary,
			},
		},
		MuiPickersCalendarHeader: {
			dayLabel: {
				color: textSecondary,
			},
			iconButton: {
				backgroundColor: 'none'
			}
		},
		MuiPickersToolbar: {
			toolbar: {
				// backgroundColor: bgColor5,
			}
		},
		MuiPopover: {
			paper: {
				// backgroundColor: bgColor1,
			},
		},
		MuiSelect: {
			icon: {
				color: textPrimary
			}
		},
		MuiTypography: {
			colorTextSecondary: {
				color: textSecondary
			}
		}
	},
	select: {
		// backgroundColor: bgColor4,
	},
	table: {
		backgroundColor: 'transparent',
		actions: {
			color: '#C9C9C9'
		},
		header: {},
		highlight: {
			// backgroundColor: bgColor5
		},
		rows: {
			// backgroundColor: bgColor5
		}
	},
	tabs: {
		// indicator: colorAccent,
		buttonTabs: {
			root: {
				// backgroundColor: bgColor4,
				color: '#666'
			},
			selected: {
				backgroundColor: '#DADBDF'
			}
		}
	},
	text: {
		primary: textPrimary,
		secondary: textSecondary,
		// tertiary: textTertiary,
		accentColor: accent,
		accent2Color: accent2,
		dark: basePageColor
		// accentColorDim: accentTrans,
		// accentColorDim2: accentTrans2,
		// lowlight: textLowlight,
		// darkText: accent,
		// mint: colorMint,
		// mintDim: colorMintDim,
		// primaryRed: lightRed,
		// lightRed: lightRed
	},
}

export default theme
