import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'

const useStyles = makeStyles(theme => ({
  maxContainer: {
    maxWidth: "1150px"
  },
  sectionTypeOne: {
    background: `linear-gradient(${theme.body.backgroundPrimaryl1}, ${theme.body.backgroundPrimaryl1}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,

    height: `calc(100vh - 75px)`
  },
  sectionTypeTwo: {
    height: `auto`,
    background: `linear-gradient(${theme.body.backgroundPrimaryl1}, ${theme.body.backgroundPrimaryl1}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,

  },
  sectionTypeThree: {
    height: `auto`,
    background: `linear-gradient(${theme.body.accent2Primaryl2}, ${theme.body.accent2Primaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,

  }
}))

export default function Section(props) {
	const classes = useStyles()

	return (
    <>
      <section className={` ${props.number === 1 ? `${classes.sectionTypeOne} flex-col justify-start` : props.number === 2 ? `${classes.sectionTypeTwo} justify-start` : `${classes.sectionTypeThree} justify-end`} w-full p-6 h-ft `}>
        {
          props.number === 1 ?
            <>
              <div className={`w-full flex flex-col flex-grow justify-start mx-auto ${classes.maxContainer}`}>
                {props.hero}
              </div>
                {props.footer}
            </>

          :
            <div className={`px-6 mx-auto ${classes.maxContainer} justify-start mx-auto ${classes.maxContainer}`}>
              {props.children}
            </div>
        }
      </section>
    </>
	)
}
