import React, {useEffect, useState} from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'

const useStyles = makeStyles(theme => ({
  yellowText: {
    color: `${theme.text.primary}`,

  },
  claimerBase: {
    color: `${theme.text.secondary}`,

  },
  bottleHead: {
		"&:hover": {
      color: `${theme.text.primary}`,
			cursor: "pointer",
      background: `linear-gradient(${theme.body.accent2Primaryl2}, ${theme.body.accent2Primaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,

		}
  },
  bottleHeadSelected: {
    background: `linear-gradient(${theme.body.accent2Primaryl3}, ${theme.body.accent2Primaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,

  },
  claimerWrapper: {
    color: theme.text.primary,
    border: `1px solid ${theme.text.tertiary}`,
    background: `linear-gradient(${theme.body.accent2Primaryl1}, ${theme.body.accent2Primaryl0}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,

  },

  claimerWrapperSelected: {
    color: theme.text.primary,
    border: `1px solid ${theme.text.tertiary}`,
    overflowY: "auto",
    background: `linear-gradient(${theme.body.accent2Primaryl3}, ${theme.body.accent2Primaryl0}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
  },
  claimed: {
    color: `${theme.text.accentColor} !important`,
    transform: `rotate(-45deg)`

  },
	web3Connector: {
		color: `${theme.text.primary}`,
    background: `linear-gradient(${theme.body.accentPrimaryl2}, ${theme.body.accentPrimaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,

		"&:hover": {
      color: `${theme.text.dark}`,

			cursor: "pointer",
      background: `linear-gradient(${theme.body.accentPrimaryl4}, ${theme.body.accentPrimaryl4}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,

		}
		// fontSize: 55
	},
  subInfo: {
		color: theme.text.tertiary
	}
}))

export default function Web3Connecter(props) {
	const classes = useStyles()
  const [batchSize, setBatchSize] = useState(20);
  const [batches, setBatches] = useState(0);

  const [commonTokens, setCommonTokens] = useState({
    "ids": [],
    "hashes": []
  });

  const [cellarTokens, setCellarTokens] = useState({
    "ids": [],
    "hashes": []
  });
  const [count, setCount] = useState(20);
  const [retrieved, setRetrieved] = useState(0);
  const [firstCommonToken, setFirstCommonToken] = useState(0);
  const [firstCellarToken, setFirstCellarToken] = useState(0);
  const [previousCommonIndex, setPreviousCommonIndex] = useState([0]);
  const [previousCellarIndex, setPreviousCellarIndex] = useState([0]);
  const [pageCommonIndex, setPageCommonIndex] = useState(count)
  const [balance, setBalance] = useState(0)
  const [selectedBatch, setSelectedBatch] = useState([])
  const [cellarBools, setCellarBools] = useState([])

  const [state, setState] = useState({
    address: null,
    rliqs: 0,
		connected: false,
    bottleheadContract: null
  });

  const [claimableBatches, setClaimableBatches] = useState([])

  useEffect(() => {
    if (props.state) {
      getSupply(props.state.proxy.nftContract, props.state.proxy.cellarContract, props.state.address)
    }
	},[props.state])

  useEffect(() => {
    if (state.connected) {
      getCommonTokens(parseInt(previousCommonIndex[previousCommonIndex.length - 1]))
      getCellarTokens(parseInt(previousCellarIndex[previousCellarIndex.length - 1]))

    }
  },[state, state.batches])

  useEffect(() => {
    if (state.connected) {
      getCommonTokens(parseInt(previousCommonIndex[previousCommonIndex.length - 1]))
    }

  },[previousCommonIndex])

  useEffect(() => {
    let newTokens = commonTokens.ids.filter(x => {
      if (x !== "0") {
        return x
      }
    })

    let retrievedCount = retrieved+newTokens.length


    if(retrievedCount !== retrieved) {
      setRetrieved(retrievedCount)
    }

    if (retrieved < state.rliqs) {
      setClaimableBatches(old => [...old, ...newTokens])
    }

  },[commonTokens])

  useEffect(() => {
    if (retrieved < state.rliqs) {
      getNext()
    }
    getCellarTokens()

  },[retrieved])

  const getSupply = async (bottleheadContract, cellarContract, user) => {
    let bal = await bottleheadContract.methods.balanceOf(user).call()
    let cellarBal = await cellarContract.methods.balanceOf(user).call()

    setBatches(Math.ceil(parseInt(bal)/parseInt(batchSize)))
    setState({
      address: user,
      rliqs: bal,
      cellars: cellarBal,
      connected: true,
      cellarContract: cellarContract,
      bottleheadContract: bottleheadContract
    })
  }

  const getCommonTokens = (i) => {
		state.bottleheadContract.methods.getOwnerTokenList(state.address, count, i).call().then((res) => {
			organizeData(res)
		});
	}

  const getCellarTokens = (i) => {
    if (claimableBatches.length) {
      state.cellarContract.methods.canClaim(claimableBatches).call().then((res) => {
        organizeCellarData(res)
      });
    }
  }

  const getNext = () => {
		if (pageCommonIndex > commonTokens.ids.length) {
			return
		}
		setCommonTokens({
			"ids": [],
			"hashes": []
		})
		setPageCommonIndex(pageCommonIndex + count)
		setPreviousCommonIndex([...previousCommonIndex, pageCommonIndex])
	}

  const organizeData = async (ids) => {
		let getFilteredIds = ids.ownerTokenIds
		let getFilteredHashes = ids.hashToTokenIds

		setCommonTokens({
			ids: getFilteredIds,
			hashes: getFilteredHashes
		});

		if (firstCommonToken === null) {
			setFirstCommonToken(ids[0])
		}
	}

  const organizeCellarData = async (ids) => {
    setCellarBools(ids)
	}

  const updateBatchSize = (up) => {
    if(up && batchSize+1 <= 30) {
      let size = batchSize + 1
      setBatches(Math.ceil(parseInt(state.rliqs)/parseInt(size)))
      setBatchSize(size)

    } else if(!up &&  batchSize-1 > 0) {
      let size = batchSize - 1
      setBatches(Math.ceil(parseInt(state.rliqs)/parseInt(size)))
      setBatchSize(size)
    } else {
      return
    }
  }

  const addToSelected = (newSelected, i) => {
    if (selectedBatch.includes(newSelected)) {
      setSelectedBatch(selectedBatch.filter(item => item !== newSelected));
    } else if (selectedBatch.length < 20 && !cellarBools[i]){
      setSelectedBatch(oldArray => [...oldArray, newSelected])
    } else {

    }
  }

  const renderClaimers = () => {
    let divs = []
    for (let i in claimableBatches) {
      divs.push(
          <>
           <div className={`${selectedBatch.includes(claimableBatches[i]) && !cellarBools[i] ? classes.bottleHeadSelected : classes.bottleHead} font-thin px-4 py-2 text-lg w-12 h-12 justify-center items-center flex relative`} onClick={() => {addToSelected(claimableBatches[i], i)}}>
             {claimableBatches[i]}
             {
               cellarBools[i] ?
               <>
                <div className={`absolute text-base ${classes.claimed}`}>
                  Claimed
                </div>
               </>

               : <></>
             }
           </div>
          </>
        )
    }

    if(divs.length && selectedBatch === false) {
      setSelectedBatch(0)
    }

    return(
      <>
        <div className={`${classes.claimerWrapper} font-thin px-4 py-2 text-xl flex flex-col w-full h-auto m-1`}>
          <div className={`flex justify-center pb-2`}>Owned Bottleheads</div>
          <div className={`flex flex-row flex-wrap h-auto flex-grow `}>
            {divs}
          </div>
        </div>
        <div className={`${classes.claimerWrapperSelected} font-thin px-4 py-2 text-xl flex flex-col w-full md:w-1/4 h-48 md:h-auto m-1`}>
          <div className={`flex justify-center`}>Selected Batch</div>
          <div className={`flex flex-row flex-wrap h-auto w-full `}>
          {selectedBatch.map((x, i) =>
             <>
             <div className={`${classes.bottleHead} font-thin px-4 py-2 text-sm w-12 h-12 justify-center items-center flex`} onClick={() => {addToSelected(x, i)}}>
               {x}
             </div>
             </>
           )}
          </div>
        </div>
      </>
    )
  }

  const claim = () => {
    if (selectedBatch.length > 0) {
      executeOrder(selectedBatch)
    } else {
      alert("Batch is empty")
    }
  }


	const executeOrder = async (batch) => {

		let getData = props.state.proxy.cellarContract.methods.claimCellars(batch);
    let x = getData.encodeABI();
		// change price to amountToSend when not using 1 gwei
    let gas = await props.state.web3.eth.estimateGas({to: props.state.proxy.cellarAddress, data: x, from: props.state.address})
		let gasPrice = await props.state.web3.eth.getGasPrice()
		// type: "0x2", needs to be specified on rinkeby since envelope type was enabled in 1559
		// remove for mainnet and ganache tests type: "0x2",

		// console.log()
		// console.log(await props.web3.eth.getBlock("pending"))
		// let block = await props.web3.eth.getBlock("pending")
		let maxPriorityFeePerGas = await props.state.web3.eth.getMaxPriorityFeePerGas()

		props.state.web3.eth.sendTransaction({to: props.state.proxy.cellarAddress, from: props.state.address, data: x, type:"0x2", maxPriorityFeePerGas: maxPriorityFeePerGas, gas: `${Math.floor(gas*1.2)}`})
  		.on('transactionHash', (hash) => {
        setSelectedBatch([])
        props.state.proxy.setPendingList(hash)
  		})
	}
	return (
		<>
      <div className={`${classes.claimerBase} flex flex-col flex-grow justify-between flex-wrap`}>
        <div className={`flex flex-col flex-grow justify-between flex-wrap  `}>
				{
					props.status ?
						<>
              <div className={`font-bold text-2xl flex justify-between`}>
                You have {state.rliqs} bottleheads
              </div>
              <div className={`font-bold  text-xl py-8`}>
                Cellars can be claimed up to 20 at a time.
              </div>

              <div className={`flex justify-start min-h-64 flex-col md:flex-row`}>
                {renderClaimers()}
              </div>
              <div className={`${classes.web3Connector} flex justify-center items-center font-thin px-10 py-6 text-3xl flex w-64 mx-auto my-4`} onClick={() => {claim(claimableBatches[selectedBatch])}}>
                Claim Batch
              </div>
						</>
					:
						<>
              <div className={`flex flex-col text-2xl flex-grow justify-start`}>
                <div className={`pb-4`}>
                  We are pleased to announce that the first airdrop for Rare Liquid Society members is ready.
                </div>
                <div className={`pb-4`}>
                  Cloud Cellars are personal web3 domains to share, showcase, store, review and trade Rare Liquid’s asset-backed NFTs.
                </div>
                <div className={`pb-4`}>
                  Cloud Cellars also come with different levels of physical storage for bottles of rare wine and spirits.
                </div>
                <div className={`pb-4`}>
                  Cloud Cellars are accessed by scanning the QR code in the generative NFTs designed by pioneering artist PXLQ.
                </div>
                <div className={``}>
                  This is a free (gas only) mint for Rare Liquid members
                </div>
              </div>
							<button className={`${classes.web3Connector} flex justify-center items-center font-thin px-10 py-6 text-3xl flex w-64 mx-auto my-4`} id={`connect_wallet`} onClick={() => {props.connectWallet()}}>
								Connect
							</button>
						</>
				}
        </div>
      </div>
    </>
	)
}
